import React from "react"
import { ColorPalette } from "../Theme"

/** props */
interface Props {
  backgroundColorOne: string
  backgroundColorTwo: string
  boxDescriptionOne: string
  boxDescriptionThree: string
  boxDescriptionTwo: string
  boxIconOne: any
  boxIconThree: any
  boxIconTwo: any
  boxSubTitleOne: string
  boxSubTitleThree: string
  boxSubTitleTwo: string
  boxTitleOne: string
  boxTitleThree: string
  boxTitleTwo: string
  colorPalette: ColorPalette
  icon: any
  subtitle: string
  title: string
}

/** const */
const ThreeBoxSection: React.FC<Props> = (props) => {
  const Icon = props.icon
  const IconOne = props.boxIconOne
  const IconTwo = props.boxIconTwo
  const IconThree = props.boxIconThree

  return (
    <section
      className="container-fluid pt-5 pb-5"
      style={{ background: `linear-gradient(${props.backgroundColorOne} 30%, ${props.backgroundColorTwo} 70%)` }}
    >
      <div className="container pl-1 pr-1">
        <div>
          <div className="text-center pb-1">
            <Icon />
          </div>
        </div>
        <div className="contentHeroSpace hidden-xs"></div>
        <h1 className="contentHeroTitle" style={{ color: "#365c70" }}>
          {props.title}
        </h1>
        <h2 className="contentHeroSubTitle mb-5" style={{ color: "#365c70", opacity: "0.7" }}>
          {props.subtitle}
        </h2>

        <div className="container">
          <div className="row">
            <div className="col-md-4 animate__animated animate__slideOutUp pb-4">
              <div className="ibox">
                <div className="ibox-content product-box bct-1">
                  <div className="product-desc">
                    <div>
                      <IconOne className="mt-2 mb-2" />
                    </div>
                    <small className="product-name text-muted">{props.boxTitleOne}</small>
                    <span className="product-name">{props.boxSubTitleOne}</span>
                    <div className="small m-t-xs">{props.boxDescriptionOne}</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4 animate__animated animate__slideOutUp pb-4">
              <div className="ibox">
                <div className="ibox-content product-box bct-1">
                  <div className="product-desc">
                    <div>
                      <IconTwo className="mt-2 mb-2" />
                    </div>
                    <small className="product-name text-muted">{props.boxTitleTwo}</small>
                    <span className="product-name">{props.boxSubTitleTwo}</span>
                    <div className="small m-t-xs">{props.boxDescriptionTwo}</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4 animate__animated animate__slideOutUp pb-4">
              <div className="ibox">
                <div className="ibox-content product-box bct-2">
                  <div className="product-desc">
                    <div>
                      <IconThree className="mt-2 mb-2" />
                    </div>
                    <small className="product-name text-muted">{props.boxTitleThree}</small>
                    <span className="product-name">{props.boxSubTitleThree}</span>
                    <div className="small m-t-xs">{props.boxDescriptionThree}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
/** export */
export default ThreeBoxSection
