import React from "react"
import { Helmet } from "react-helmet"
import config from "../../site.config"

/** components */
import Layout from "../components/Layout"
import { theme } from "../components/Theme"

/** dynamic sections */
import HeroTopSection from "../components/dynamic-sections/HeroTopSection"
import RibbonSection from "../components/dynamic-sections/RibbonSection"
import GalleryImageSection from "../components/dynamic-sections/GalleryImageSection"
import AwardsSection from "../components/dynamic-sections/AwardsSection"
import ThreeBoxSection from "../components/dynamic-sections/ThreeBoxSection"
import HeroRightSection from "../components/dynamic-sections/HeroRightSection"
import FourImageTemplate from "../components/dynamic-sections/FourImageTemplate"
import TwoImageSection from "../components/dynamic-sections/TwoImageSection"
import GalleryImageLargeSection from "../components/dynamic-sections/GalleryImageLargeSection"

/** color theme */
const colorPalette = theme.colorPalettes.blue

/** svg */
import NorhartIcon from "../../assets/norhart-icon.svg"
import ValuesIcon from "../../assets/values.svg"
import ValuesRespectIcon from "../../assets/value-complete-respect.svg"
import NorhartDarkCircleIcon from "../../assets/norhart-dark-circle-icon.svg"

/** props */
interface Props {
  data: any
}

/** const */
const GivingBackPage: React.FC<Props> = (props) => {
  const ldJson = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        item: {
          "@id": "/giving-back/",
          name: "Giving Back | Norhart",
          image: `${config.siteMetadata.siteUrl}/giving-back/giving-back-hero-open-graph.png`,
        },
      },
    ],
  }

  return (
    <Layout
      title="Giving Back | Norhart"
      description="Norhart loves giving back to the communities where we work and build apartments. Norhart has a solid commitment to charitable giving, donations, and volunteerism in Forest Lake, Blaine, and Oakdale, MN."
      keywords="norhart, luxury, innovative, smart, technology, apartment, apartments, building, buildings, mn, minnesota, minneapolis, st paul, rent, rentals, resident, residents, forest lake, blaine, circle pines, lexington, coon rapids, Oakdale, encore apartments, lexington lofts apartments, Oakdale apartments, gateway green apartments, mill pond apartments, birchview apartments, legacy pointe apartments, heritage heights apartments, springwood apartments, greystone apartments, northbrook apartments, construction, norhart construction, lean construction"
      imageTwitter={`${config.siteMetadata.siteUrl}/giving-back/norhart-giving-back-hero-twitter-card.png`}
      imageOpenGraph={`${config.siteMetadata.siteUrl}/giving-back/norhart-giving-back-hero-open-graph.png`}
      colorPalette={colorPalette}
    >
      <HeroTopSection
        title="Giving Back"
        subtitle="This Is The Norhart Way"
        imageTitle="Norhart Giving Back"
        image="/giving-back/norhart-giving-back-hero.png"
        colorPalette={colorPalette}
      />

      <RibbonSection
        title="Experience Norhart Giving"
        tagLine="We love to give back to our communties"
        colorPalette={colorPalette}
      />

      <Helmet>
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
      </Helmet>

      <GalleryImageSection
        backgroundColorOne={colorPalette.cardAccentTop}
        backgroundColorTwo={colorPalette.cardAccentTop}
        icon={NorhartDarkCircleIcon}
        iconTitle="Norhart Giving"
        title="We ❤️ To Help"
        subtitle="Norhart loves giving back to the communities where we work and live. Norhart has a solid commitment to changing people's lives, helping our communities, and giving back."
        imageTitleOne="Giving Back Image 1"
        imageOne="/giving-back/gallery/norhart-giving-back-1.webp"
        imageTitleTwo="Giving Back Image 2"
        imageTwo="/giving-back/gallery/norhart-giving-back-2.webp"
        imageTitleThree="Giving Back Image 3"
        imageThree="/giving-back/gallery/norhart-giving-back-3.png"
        imageTitleFour="Giving Back Image 4"
        imageFour="/giving-back/gallery/norhart-giving-back-4.webp"
        imageTitleFive="Giving Back Image 5"
        imageFive="/giving-back/gallery/norhart-giving-back-5.png"
        imageTitleSix="Giving Back Image 6"
        imageSix="/giving-back/gallery/norhart-giving-back-6.webp"
        colorPalette={colorPalette}
        badge={false}
      />

      <ThreeBoxSection
        backgroundColorOne="#EFF2F4"
        backgroundColorTwo="#EFF2F4"
        boxDescriptionOne="Included in our corporate DNA is the responsibility, desire, and drive to help give back to the communities we serve."
        boxDescriptionThree="Our people love helping the communities we work in. Keep an eye out for our Norhart teams in the community!"
        boxDescriptionTwo="We love to donate to: people, families, and communities that are in need. It's just who we are and what we do."
        boxIconOne={ValuesRespectIcon}
        boxIconThree={ValuesRespectIcon}
        boxIconTwo={ValuesRespectIcon}
        boxSubTitleOne="Giving"
        boxSubTitleThree="Volunteerism"
        boxSubTitleTwo="Donations"
        boxTitleOne="Norhart"
        boxTitleThree="Norhart"
        boxTitleTwo="Norhart"
        colorPalette={colorPalette}
        icon={ValuesIcon}
        subtitle="From its inception, Norhart's DNA has included: charitable giving, donations, and volunteerism. When we say we want to make people's lives better, it is not just a marketing slogan. It's who we are."
        title="Culture of Giving"
      />

      <TwoImageSection
        backgroundColorOne="#E5E8e9"
        backgroundColorTwo="#EFF2F4"
        fontTextColor="#365c70"
        icon={NorhartDarkCircleIcon}
        iconTitle="Norhart Projects"
        title="Projects We Love"
        subtitle="Here is a tiny group of charities and activities Norhart loves and supports. We are always on the lookout for more ways to give back. #NorhartGivingBack"
        imageTitleOne="Holiday Giving"
        imageOneText="Norhart has a long tradition of holiday giving year-round to residents, individuals, and families in need of food and clothing."
        imageOne="/giving-back/holiday-giving.png"
        imageOneAlt="Holiday Giving"
        imageOnePill=""
        imageTitleTwo="Special Olympics"
        imageTwoText="Special Olympics provides sports training and athletic competition for children and adults with intellectual disabilities."
        imageTwo="/giving-back/special-olympics.png"
        imageTwoAlt="Special Olympics "
        imageTwoPill=""
        pillActive="Active"
        colorPalette={colorPalette}
        imageTopPad={0}
      />

      <GalleryImageLargeSection
        backgroundColorOne={colorPalette.cardAccentTop}
        backgroundColorTwo={colorPalette.cardAccentTop}
        icon={NorhartDarkCircleIcon}
        iconTitle="Volunteerism Life"
        title="We Embrace Giving Back"
        subtitle="Norhart Teams embrace Giving Back to our communities: We love #GivingBack; it's a defining trait of the Norhart Culture! #Donations #Volunteerism #NorhartCulture "
        imageTitleOne="Norhart Giving Back Team Image 1"
        imageOne="/giving-back/gallery/1.png"
        imageTitleTwo="Norhart Giving Back Team Image 2"
        imageTwo="/giving-back/gallery/2.png"
        imageTitleThree="Norhart Giving Back Team Image 3"
        imageThree="/giving-back/gallery/3.png"
        imageTitleFour="Norhart Giving Back Team Image 4"
        imageFour="/giving-back/gallery/4.png"
        imageTitleFive="Norhart Giving Back Team Image 5"
        imageFive="/giving-back/gallery/5.png"
        imageTitleSix="Norhart Giving Back Team Image 6"
        imageSix="/giving-back/gallery/6.png"
        imageTitleSeven="Norhart Giving Back Team Image 7"
        imageSeven="/giving-back/gallery/7.png"
        imageTitleEight="Norhart Giving Back Team Image 8"
        imageEight="/giving-back/gallery/8.png"
        imageTitleNine="Norhart Giving Back Team Image 9"
        imageNine="/giving-back/gallery/9.png"
        imageTitleTen="Norhart Giving Back Team Image 10"
        imageTen="/giving-back/gallery/10.png"
        imageTitleEleven="Norhart Giving Back Team Image 11"
        imageEleven="/giving-back/gallery/11.png"
        imageTitleTweleve="Norhart Giving Back Team Image 12"
        imageTweleve="/giving-back/gallery/12.png"
        colorPalette={colorPalette}
        buttonName="Norhart Culture Instagram"
        buttonUrl="https://www.instagram.com/norhartculture/"
        badge={false}
      />

      <HeroRightSection
        backgroundColorOne={colorPalette.heroTop}
        backgroundColorTwo={colorPalette.heroBottom}
        fontTextColor="#FFFFFF"
        titleTopLeft="Norhart Benefit"
        badgeTitle="Giving Back"
        title="Volunteer Time Off"
        subtitle="We pay our people 16hrs each year to help give back to our communities. Be a part of our giving team. #FeelsGreat"
        imageTitle="Norhart Paid Volunteer Time Off"
        image="/giving-back/norhart-careers-join-our-team.png"
        buttonName="Join The Team"
        buttonUrl="/careers/"
        colorPalette={colorPalette}
        colSizeOne={4}
        colSizeTwo={8}
        bottomMargin={0}
        asoEffect="fade-in"
      />

      <AwardsSection
        header="Norhart Apartments"
        title="We Build More Than Apartments"
        subtitle="We have been called out for being an innovator, setting the bar high, and pushing the industry in a new direction. That's just who we are. Experience the Norhart difference for yourself!"
        colorPalette={colorPalette}
      />
    </Layout>
  )
}

/** export */
/** export */
export default GivingBackPage
